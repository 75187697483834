import * as actions from "./actions";
import {requestFailedValue, requestInitValue, requestSucceededValue} from "../../../functions/reduxFunctions";

// Partial global store for requests data management
const initialState = {
    all: {failed: false, loading: false, succeeded: false, message: ""},
    list: {failed: false, loading: false, succeeded: false, message: ""},
    next: {failed: false, loading: false, succeeded: false, message: ""},
    master: {failed: false, loading: false, succeeded: false, message: ""},
    internal: {failed: false, loading: false, succeeded: false, message: ""},
    external: {failed: false, loading: false, succeeded: false, message: ""},
    collector: {failed: false, loading: false, succeeded: false, message: ""},
};

// Reduce
function reduce(state = initialState, action) {
    let nextState;
    switch (action.type) {
        // ======================================================== Sims
        // Resolve event to set sims init request store data
        case actions.STORE_SIMS_REQUEST_INIT:
            nextState = {...state, list: requestInitValue()};
            return nextState || state;
        // Resolve event to set sims failed request store data
        case actions.STORE_SIMS_REQUEST_FAILED:
            nextState = {...state, list: requestFailedValue(action.message)};
            return nextState || state;
        // Resolve event to set sims succeeded request store data
        case actions.STORE_SIMS_REQUEST_SUCCEEDED:
            nextState = {...state, list: requestSucceededValue(action.message)};
            return nextState || state;
        // Resolve event to set sims reset request store data
        case actions.STORE_SIMS_REQUEST_RESET:
            nextState = {...state, list: initialState.list};
            return nextState || state;
        // ======================================================== Next sims
        // Resolve event to set next sims init request store data
        case actions.STORE_NEXT_SIMS_REQUEST_INIT:
            nextState = {...state, next: requestInitValue()};
            return nextState || state;
        // Resolve event to set next sims failed request store data
        case actions.STORE_NEXT_SIMS_REQUEST_FAILED:
            nextState = {...state, next: requestFailedValue(action.message)};
            return nextState || state;
        // Resolve event to set next sims succeeded request store data
        case actions.STORE_NEXT_SIMS_REQUEST_SUCCEEDED:
            nextState = {...state, next: requestSucceededValue(action.message)};
            return nextState || state;
        // Resolve event to set next sims reset request store data
        case actions.STORE_NEXT_SIMS_REQUEST_RESET:
            nextState = {...state, next: initialState.next};
            return nextState || state;
        // ======================================================== All sims
        // Resolve event to set all sims  init request store data
        case actions.STORE_ALL_SIMS_REQUEST_INIT:
            nextState = {...state, all: requestInitValue()};
            return nextState || state;
        // Resolve event to set all sims failed request store data
        case actions.STORE_ALL_SIMS_REQUEST_FAILED:
            nextState = {...state, all: requestFailedValue(action.message)};
            return nextState || state;
        // Resolve event to set all sims succeeded request store data
        case actions.STORE_ALL_SIMS_REQUEST_SUCCEEDED:
            nextState = {...state, all: requestSucceededValue(action.message)};
            return nextState || state;
        // Resolve event to set all sims reset request store data
        case actions.STORE_ALL_SIMS_REQUEST_RESET:
            nextState = {...state, all: initialState.all};
            return nextState || state;
        // ======================================================== All collector sims
        // Resolve event to set all collector sims init request store data
        case actions.STORE_ALL_COLLECTOR_SIMS_REQUEST_INIT:
            nextState = {...state, collector: requestInitValue()};
            return nextState || state;
        // Resolve event to set all collector sims failed request store data
        case actions.STORE_ALL_COLLECTOR_SIMS_REQUEST_FAILED:
            nextState = {...state, collector: requestFailedValue(action.message)};
            return nextState || state;
        // Resolve event to set all collector sims succeeded request store data
        case actions.STORE_ALL_COLLECTOR_SIMS_REQUEST_SUCCEEDED:
            nextState = {...state, collector: requestSucceededValue(action.message)};
            return nextState || state;
        // Resolve event to set all collector sims reset request store data
        case actions.STORE_ALL_COLLECTOR_SIMS_REQUEST_RESET:
            nextState = {...state, collector: initialState.collector};
            return nextState || state;
        // ======================================================== All internal sims
        // Resolve event to set all internal sims init request store data
        case actions.STORE_ALL_INTERNAL_SIMS_REQUEST_INIT:
            nextState = {...state, internal: requestInitValue()};
            return nextState || state;
        // Resolve event to set all internal sims failed request store data
        case actions.STORE_ALL_INTERNAL_SIMS_REQUEST_FAILED:
            nextState = {...state, internal: requestFailedValue(action.message)};
            return nextState || state;
        // Resolve event to set all internal sims succeeded request store data
        case actions.STORE_ALL_INTERNAL_SIMS_REQUEST_SUCCEEDED:
            nextState = {...state, internal: requestSucceededValue(action.message)};
            return nextState || state;
        // Resolve event to set all internal sims reset request store data
        case actions.STORE_ALL_INTERNAL_SIMS_REQUEST_RESET:
            nextState = {...state, internal: initialState.internal};
            return nextState || state;
        // ======================================================== All external sims
        // Resolve event to set all external sims init request store data
        case actions.STORE_ALL_EXTERNAL_SIMS_REQUEST_INIT:
            nextState = {...state, external: requestInitValue()};
            return nextState || state;
        // Resolve event to set all external sims failed request store data
        case actions.STORE_ALL_EXTERNAL_SIMS_REQUEST_FAILED:
            nextState = {...state, external: requestFailedValue(action.message)};
            return nextState || state;
        // Resolve event to set all external sims succeeded request store data
        case actions.STORE_ALL_EXTERNAL_SIMS_REQUEST_SUCCEEDED:
            nextState = {...state, external: requestSucceededValue(action.message)};
            return nextState || state;
        // Resolve event to set all external sims reset request store data
        case actions.STORE_ALL_EXTERNAL_SIMS_REQUEST_RESET:
            nextState = {...state, external: initialState.external};
            return nextState || state;
        // ======================================================== All master sims
        // Resolve event to set all master sims init request store data
        case actions.STORE_ALL_MASTER_SIMS_REQUEST_INIT:
            nextState = {...state, master: requestInitValue()};
            return nextState || state;
        // Resolve event to set all master sims failed request store data
        case actions.STORE_ALL_MASTER_SIMS_REQUEST_FAILED:
            nextState = {...state, master: requestFailedValue(action.message)};
            return nextState || state;
        // Resolve event to set all master sims succeeded request store data
        case actions.STORE_ALL_MASTER_SIMS_REQUEST_SUCCEEDED:
            nextState = {...state, master: requestSucceededValue(action.message)};
            return nextState || state;
        // Resolve event to set all master sims reset request store data
        case actions.STORE_ALL_MASTER_SIMS_REQUEST_RESET:
            nextState = {...state, master: initialState.master};
            return nextState || state;
        // ========================================================
        // Unknown action
        default: return state;
    }
}

export default reduce
